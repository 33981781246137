import React from 'react';
import { signInWithGoogle } from '../../Firebase/config';

const Login = () => {

  return (
    <button onClick={signInWithGoogle}>Google Signin</button>
  );
};

export default Login;
