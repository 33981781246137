import React from 'react';
import 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import Login from '../components/user/login';
import Logout from '../components/user/logout';
import './App.css';
import { auth } from '../Firebase/config';

const App = () => {
  const [user] = useAuthState(auth);

  // console.log(user)

  return (
    <div className="App">
      <header className="App-header">
        <h2>Team Player!!!</h2>
      </header>
      <section>
        {user ? <h1>Welcome {user.displayName}</h1> : <Login />}
        <Logout />
      </section>
    </div>
  );
}

export default App;
