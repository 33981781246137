import React from 'react';
import { signOut, auth } from '../../Firebase/config';


const Logout = () => {

  return auth.currentUser && <button onClick={() => signOut()}>Logout</button>
}

export default Logout;
